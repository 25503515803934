import React, { useState, useEffect } from 'react';
import {
  AppBar, Menu, Toolbar, Button, ThemeProvider, CssBaseline, IconButton, TextField, Grid, Box, Typography, Container, MenuItem, FormControl, InputLabel, Select, Card, CardContent, Avatar, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress, Snackbar, Alert
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../logo.png'; // Ensure correct path
import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { Close as CloseIcon } from '@mui/icons-material';
 import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
const theme = createTheme();

const UserProfile = () => {

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const handleUpdateSuccess = () => {
    setOpenSuccessModal(true);
  };

  const [preview, setPreview] = useState(null);


  const [profile, setProfile] = useState({
    firstName: '',
    fatherName: '',
    grandFatherName: '',
    email: '',
    phoneNumber: '',
    file_path: '',
    bsccgpa: '',
    masterscgpa: '',
    gender: '',
    institutionBsc: '',
    customInstitutionBsc: '',
    institutionMasters: '',
    customInstitutionMasters: '',
    examcenter: '',
    program: '',
    file: null,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const [examCenters, setExamCenters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [newProfilePicture, setNewProfilePicture] = useState(false);
  const examCenterName = examCenters.find(center => center.id === profile.examcenter)?.name || '';

  const navigate = useNavigate();

  const fetchProfile = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.get('https://ngat.ethernet.edu.et/api/user-profile', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProfile(response.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('authToken');
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchInstitutions = async () => {
    try {
      const response = await axios.get('https://ngat.ethernet.edu.et/api/signup-inist');
      setInstitutions(response.data);
    } catch (error) {
      console.error('Error fetching institutions:', error);
    }
  };

  const fetchExamCenters = async () => {
    try {
      const response = await axios.get('https://ngat.ethernet.edu.et/api/exam-centers');
      setExamCenters(response.data);
    } catch (error) {
      console.error('Error fetching exam centers:', error);
    }
  };

  useEffect(() => {
    fetchProfile();
    fetchInstitutions();
    fetchExamCenters();
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setPreview(fileURL); // Set preview for display
      setProfile(prevProfile => ({
        ...prevProfile,
        file: file // Store the file in profile state
      }));
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };







  const handleCancelClick = () => {
    setIsEditing(false);
    fetchProfile(); // Reset profile data
  };

  const handleUpdateClick = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) return;

    // Prepare FormData
    const formData = new FormData();
    formData.append('firstName', profile.firstName);
    formData.append('fatherName', profile.fatherName);
    formData.append('grandFatherName', profile.grandFatherName);
    formData.append('email', profile.email);
    formData.append('phoneNumber', profile.phoneNumber);
    formData.append('bsccgpa', profile.bsccgpa);
    formData.append('masterscgpa', profile.masterscgpa);
    formData.append('gender', profile.gender);
    formData.append('institutionBsc', profile.institutionBsc);
    formData.append('customInstitutionBsc', profile.customInstitutionBsc);
    formData.append('institutionMasters', profile.institutionMasters);
    formData.append('customInstitutionMasters', profile.customInstitutionMasters);
    formData.append('examcenter', profile.examcenter);

    formData.append('program', profile.program);
    formData.append('speciality', profile.speciality);

    if (profile.file) {
      formData.append('file', profile.file);
    }

    // Log FormData contents for debugging
    for (let [key, value] of formData.entries()) {
      if (value instanceof File) {
        console.log(`${key}: ${value.name}`);
      } else {
        console.log(`${key}: ${value}`);
      }
    }

    setUpdateLoading(true);

    try {
      const response = await axios.post(
        'https://ngat.ethernet.edu.et/api/user-profile-update', // Ensure this is the correct URL
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setProfile(response.data);
      setOpenSuccessModal(true);

    } catch (error) {
      console.error('Error updating profile:', error.response?.data);
      if (error.response && error.response.data && error.response.data.errors) {
        setUpdateError(error.response.data.errors);
      } else {
        setUpdateError({ general: ['An unexpected error occurred.'] });
      }
    } finally {
      setUpdateLoading(false);
    }
  };





  const encodeFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file); // Converts file to base64 encoded string
    });
  };






  const handleContactClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleContactClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.removeItem('authToken');
    window.location.href = '/login';
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: 'rgb(18,82,166)',
      },
      background: {
        default: '#f5f5f5',
      },
    },
    typography: {
      fontFamily: '"Times New Roman", Times, serif',
    },
  });
  useEffect(() => {
    // Reset preview if profile file_path is updated
    setPreview(null);
  }, [profile.file_path]);

  const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      borderRadius: '16px',
      padding: theme.spacing(2),
      boxShadow: theme.shadows[5],
    },
  }));

  const Title = styled(DialogTitle)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3),
    fontSize: '1.5rem',
  }));

  const Content = styled(DialogContent)(({ theme }) => ({
    padding: theme.spacing(2, 3),
    textAlign: 'center',
  }));

  const Actions = styled(DialogActions)(({ theme }) => ({
    padding: theme.spacing(1, 3),
    justifyContent: 'center',
  }));

  const SuccessModal = ({ open, onClose }) => (
    <CustomDialog open={open} onClose={onClose} aria-labelledby="success-dialog-title">
      <Title id="success-dialog-title">
        <Typography variant="h6">Profile updated</Typography>
        <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Title>
      <Content>
        <Typography variant="body1">Profile updated successfully!!</Typography>
      </Content>
      <Actions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            window.location.reload(); // Reload the page when OK is clicked
          }}
        >
          OK
        </Button>
      </Actions>
    </CustomDialog>
  );

  const downloadAdmissionTicket = async () => {
    try {
      const response = await axios.post('https://ngat.ethernet.edu.et/api/download-ticket', { username: profile.username }, { responseType: 'blob' });
  
      // Create a link element, set its href to a URL created from the blob, and trigger a click to download
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'NGAT_enterance_ticket.pdf'); // Name of the file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading admission ticket:', error);
    }
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <img src={logo} alt="Ministry of Education Logo" style={{ maxHeight: '50px', marginRight: '10px' }} />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Ministry of Education
          </Typography>
          <Button color="inherit" onClick={() => navigate('/')}>Home</Button>
          <Button color="inherit" onClick={() => navigate('/about')}>About</Button>
          <Button color="inherit" onClick={handleContactClick}>Contact</Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleContactClose}
          >
            <MenuItem onClick={handleContactClose}>
              <a href="https://t.me/MoENGAT" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>Telegram</a>
            </MenuItem>
            <MenuItem onClick={handleContactClose}>
              <a href="https://chat.whatsapp.com/JSQtXWXvoYqLHj4gFvpouN" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>WhatsApp</a>
            </MenuItem>
          </Menu>          <Button color="inherit" onClick={handleLogout}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Container component="main" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ height: '100%', minHeight: 600, boxShadow: 3, borderRadius: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  General Information
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      fullWidth
                      autoComplete="given-name"
                      value={profile.firstName}
                      onChange={handleChange}
                      disabled={!isEditing}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="fatherName"
                      name="fatherName"
                      label="Father Name"
                      fullWidth
                      value={profile.fatherName}
                      onChange={handleChange}
                      disabled={!isEditing}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="grandFatherName"
                      name="grandFatherName"
                      label="Grand Father Name"
                      fullWidth
                      value={profile.grandFatherName}
                      onChange={handleChange}
                      disabled={!isEditing}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="email"
                      name="email"
                      label="Email Address"
                      fullWidth
                      autoComplete="email"
                      value={profile.email}
                      onChange={handleChange}
                      disabled="true"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="phoneNumber"
                      name="phoneNumber"
                      label="Phone Number"
                      fullWidth
                      autoComplete="phone"
                      value={profile.phoneNumber}
                      onChange={handleChange}
                      disabled={!isEditing}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="gender-label">Gender</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="gender"
                        name="gender"
                        value={profile.gender}
                        onChange={handleChange}
                        disabled={!isEditing}
                      >
                        <MenuItem value={'Male'}>Male</MenuItem>
                        <MenuItem value={'Female'}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    Educational Background
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="speciality-label">Field of Speciality applying for</InputLabel>

                        <Select
                          labelId="speciality-label"
                          id="speciality"
                          name="speciality"
                          value={profile.speciality || ''} // Ensure value is either a valid option or empty string
                          label="Field of Speciality applying for"
                          onChange={handleChange}
                          disabled={!isEditing}
                        >
                          <MenuItem value="Engineering">Engineering</MenuItem>
                          <MenuItem value="Computational Sciences">Computational Sciences</MenuItem>
                          <MenuItem value="Life Sciences">Life Sciences</MenuItem>
                          <MenuItem value="Physical Sciences">Physical Sciences</MenuItem>
                          <MenuItem value="Medical and Health Sciences">Medical and Health Sciences</MenuItem>
                          <MenuItem value="Animal Sciences">Animal Sciences</MenuItem>
                          <MenuItem value="Plant Sciences">Plant Sciences</MenuItem>
                          <MenuItem value="Business and Economics">Business and Economics</MenuItem>
                          <MenuItem value="Educational Related Programs">Educational Related Programs</MenuItem>
                          <MenuItem value="Social Sciences">Social Sciences</MenuItem>
                          <MenuItem value="Humanities">Humanities</MenuItem>
                          <MenuItem value="Language, Folklore and Communication">Language, Folklore and Communication</MenuItem>
                          <MenuItem value="Military Sciences">Military Sciences</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>


                    <Grid item xs={12}>
                      <TextField
                        id="bsccgpa"
                        name="bsccgpa"
                        label="BSc CGPA"
                        fullWidth
                        value={profile.bsccgpa}
                        onChange={handleChange}
                        disabled={!isEditing}
                      />
                    </Grid>
                    {profile.program === 'PhD' && (
                      <>
                        <Grid item xs={12}>
                          <TextField
                            id="masterscgpa"
                            name="masterscgpa"
                            label="Masters CGPA"
                            fullWidth
                            value={profile.masterscgpa}
                            onChange={handleChange}
                            disabled={!isEditing}
                          />
                        </Grid>
                        {/* 
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id="institutionMasters-label">Masters Institution</InputLabel>
                            <Select
                              labelId="institutionMasters-label"
                              id="institutionMasters"
                              name="institutionMasters"
                              value={profile.institutionMasters}
                              onChange={handleChange}
                              disabled={!isEditing}
                            >
                              {institutions.map((institution) => (
                                <MenuItem key={institution.id} value={institution.id}>
                                  {institution.name}
                                </MenuItem>
                              ))}
                              <MenuItem value="Other">Other</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        */}

                        {/*
                        {profile.institutionMasters === 'Other' && (
                          <Grid item xs={12}>
                            <TextField
                              id="customInstitutionMasters"
                              name="customInstitutionMasters"
                              label="Custom Masters Institution Name"
                              fullWidth
                              value={profile.customInstitutionMasters}
                              onChange={handleChange}
                              disabled={!isEditing}
                            />
                          </Grid>
                        )}

                        */}
                      </>
                    )}


                    {/* 
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="institutionBsc-label">BSc Institution</InputLabel>
                        <Select
                          labelId="institutionBsc-label"
                          id="institutionBsc"
                          name="institutionBsc"
                          value={profile.institutionBsc}
                          onChange={handleChange}
                          disabled={!isEditing}
                        >
                          {institutions.map((institution) => (
                            <MenuItem key={institution.id} value={institution.id}>
                              {institution.name}
                            </MenuItem>
                          ))}
                          <MenuItem value="Other">Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {profile.institutionBsc === 'Other' && (
                      <Grid item xs={12}>
                        <TextField
                          id="customInstitutionBsc"
                          name="customInstitutionBsc"
                          label="Custom BSc Institution Name"
                          fullWidth
                          value={profile.customInstitutionBsc}
                          onChange={handleChange}
                          disabled={!isEditing}
                        />
                      </Grid>
                    )}
                     */}
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="examcenter-label">Exam Center</InputLabel>
                        <Select
                          labelId="examcenter-label"
                          id="examcenter"
                          name="examcenter"
                          value={profile.examcenter}
                          onChange={handleChange}
                          disabled={!isEditing}
                        >
                          {examCenters.map((center) => (
                            <MenuItem key={center.id} value={center.id}>
                              {center.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
               
                 
                  </Grid>
                </Box>
              </CardContent>
            </Card>

            { /* 
            {isEditing ? (
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateClick}
                  disabled={updateLoading}
                >
                  Update
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{ ml: 2 }}
                  onClick={handleCancelClick}
                  disabled={updateLoading}
                >
                  Cancel
                </Button>
                {updateLoading && <CircularProgress size={24} sx={{ ml: 2 }} />}
              </Box>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={handleEditClick}
              >
                Edit Profile
              </Button>
            )}

            {updateError && (
              <Box sx={{ mt: 2 }}>
                {Object.keys(updateError).map((key) => (
                  <Typography key={key} color="error">
                    {updateError[key].join(', ')}
                  </Typography>
                ))}
              </Box>
            )}
*/}
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ p: 2, boxShadow: 3, borderRadius: 2 }}>
              <CardContent sx={{ textAlign: 'center' }}>
                <Avatar
                  alt="Profile Picture"
                  src={
                    preview ||
                    (typeof profile.file_path === 'string'
                      ? `https://ngat.ethernet.edu.et/${profile.file_path.replace(/\\/g, '/')}`
                      : null)
                  }
                  sx={{ width: 120, height: 150, margin: 'auto' }}
                />
              </CardContent>

              <TextField
                type="file"
                fullWidth
                id="file"
                name="file"
                label="Update profile picture here (Passport size)"
                InputLabelProps={{ shrink: true }}
                onChange={handleFileChange}
                disabled={!isEditing}

              />
            </Card>








            <Card sx={{ mt: 5, p: 2, boxShadow: 3, borderRadius: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ textAlign: 'left' }}>
                  Payment Information
                </Typography>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}></Box>
                <Typography variant="body1" sx={{ mt: 1, mb: 2, textAlign: 'left' }}>
                  Service Payment : <span style={{ fontWeight: 'bold' }}>750 ETB</span>
                </Typography>
                
              
                <Typography variant="body1" sx={{ mb: 2, textAlign: 'left' }}>
                  Registration Status:
                  <span style={{ color: profile.is_paid ? 'green' : 'red', fontWeight: 'bold' }}>
                    {loading ? '' : (profile.is_paid ? 'Completed' : 'Incomplete')}
                  </span>
                </Typography>

                
                <Typography variant="body1" sx={{ mb: 2, textAlign: 'left' }}>
                  PRN: <span style={{ color: 'black', fontWeight: 'bold' }}>{profile.reference_number}</span>
                </Typography>
                

                {!loading && profile.is_paid && (
                  <Typography variant="body1" sx={{ mt: 1, mb: 2, textAlign: 'left', color: 'green' }}>
                    Registration Completed
                  </Typography>
                )}
                
                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  disabled={loading || profile.is_paid} // Disable if loading or already paid
                  onClick={() => setOpenModal(true)} // Open modal on button click
                >
                  Go to Payment
                </Button>
                

              </CardContent>
            </Card>
            {profile.is_paid === 1 && (
  <Card sx={{ mt: 5, p: 2, boxShadow: 3, borderRadius: 2 }}>
    <CardContent>
      <Typography variant="h6" gutterBottom sx={{ textAlign: 'left' }}>
        Exam credentials and schedule
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}></Box>
      <Typography variant="body1" sx={{ mt: 1, mb: 2, textAlign: 'left' }}>
        Username : <span style={{ fontWeight: 'bold' }}>{profile.username}</span>
      </Typography>
      <Typography variant="body1" sx={{ mb: 2, textAlign: 'left' }}>
        Password : <span style={{ fontWeight: 'bold' }}>{profile.password}</span>
      </Typography>
      
      <Typography variant="body1" sx={{ mb: 2, textAlign: 'left' }}>
        Exam Center : <span style={{ fontWeight: 'bold' }}>{examCenterName}</span>

      
      </Typography>
        
      
     

  
     
      <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
        <br />
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}></Box>
        <Button variant="contained" color="primary" onClick={downloadAdmissionTicket}>
          Download Exam Entry Ticket
        </Button>
      </Typography>

 
       
    </CardContent>
  </Card>
)}


           


          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="payment-details-dialog-title"
        aria-describedby="payment-details-dialog-description"
      >
        <DialogTitle id="payment-details-dialog-title">Payment Details</DialogTitle>
        <DialogContent>
          <DialogContentText id="payment-details-dialog-description">
            <p>Your payment reference number is : <b>{profile.reference_number}</b></p>
            <p>Service provider: Ministry of Education</p>
            <p>Please follow the below instruction and steps for your payment:</p>
            <ol>
              <li>Open telebirr Mobile App</li>
              <li>Select "Payment" option</li>
              <li>Select "Education fee"</li>
              <li>Choose "Ministry of Education"</li>
              <li>Enter the payment reference: <b>{profile.reference_number}</b></li>
              <li>You will see the payment details including amount</li>
              <li>Click Next and Enter your Tellebirr PIN and finish</li>
            </ol>
            <p>Once you have completed the payment process, you will receive a short SMS verifying the transaction from Telebirr.</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Profile updated successfully!
        </Alert>
      </Snackbar>

      <div>
        {/* Your other component code */}

        <SuccessModal
          open={openSuccessModal}
          onClose={() => setOpenSuccessModal(false)}
        />
      </div>
    </ThemeProvider>
  );
};

export default UserProfile;
